@tailwind base;
@tailwind components;
@tailwind utilities;

.h1 {
  @apply text-[64px] leading-[96px];
}

.h2 {
  @apply text-[56px] leading-[84px];
}

.h3 {
  @apply text-[32px] leading-[48px];
}

.h4 {
  @apply font-bold text-[24px] leading-[36px];
}

.h5 {
  @apply font-bold text-[20px] leading-[30px];
}

.body1 {
  @apply text-[20px] leading-[30px];
}

.body2 {
  @apply text-[18px] leading-[27px];
}

.body3 {
  @apply text-[16px] leading-[24px];
}

.tiny-text {
  @apply text-[12px] leading-[18px];
}

.h72 {
  letter-spacing: -1.61px;
  @apply text-[72px] leading-[90px];
}

.h64 {
  letter-spacing: -1.43px;
  @apply text-[64px] leading-[80px];
}

.h56 {
  letter-spacing: -1.25px;
  @apply text-[56px] leading-[70px];
}

.h48 {
  letter-spacing: -1.02px;
  @apply text-[48px] leading-[60px];
}

.h40 {
  letter-spacing: -0.89px;
  @apply text-[40px] leading-[50px];
}

.h36 {
  letter-spacing: -0.79px;
  @apply text-[36px] leading-[45px];
}

.h28 {
  letter-spacing: -0.52px;
  @apply text-[28px] leading-[42px];
}

.h24 {
  letter-spacing: -0.47px;
  @apply text-[24px] leading-[36px];
}

.body20 {
  letter-spacing: -0.33px;
  @apply text-[20px] leading-[30px];
}

.body18 {
  letter-spacing: -0.26px;
  @apply text-[18px] leading-[27px];
}

.body16 {
  letter-spacing: -0.18px;
  @apply text-[16px] leading-[24px];
}

.body14 {
  letter-spacing: -0.09px;
  @apply text-[14px] leading-[20px];
}

.label12 {
  letter-spacing: 0.01px;
  @apply text-[12px] leading-[18px];
}

.btn-primary {
  @apply bg-primary-600 disabled:bg-primary-200 body1 font-semibold text-white px-6 py-4 rounded-xl hover:bg-primary-800 cursor-pointer disabled:cursor-not-allowed;
}

.btn-secondary {
  @apply bg-neutral-500 body3 disabled:bg-primary-200  text-white px-6 py-3 rounded-lg hover:bg-neutral-600 disabled:cursor-not-allowed;
}

.btn-ghost {
  @apply bg-white body1 font-semibold text-neutral-600 px-6 py-4 rounded-xl hover:bg-neutral-300 disabled:cursor-not-allowed border-2 border-neutral-300 disabled:opacity-40;
}

.input-field {
  @apply placeholder:text-neutral-400 border  w-full rounded-[12px] focus:outline-0  focus:border-neutral-400 text-neutral-800;
}

.search-value em {
  @apply underline text-brand underline-offset-4 !not-italic;
}

.no-focus-outline {
  @apply focus-visible:outline-none active:outline-0 active:outline-none;
}

.purple-gradient-text {
  @apply bg-purple-gradient-2 bg-clip-text text-transparent;
}

* {
  scrollbar-width: none; /* For Firefox */
}

*::-webkit-scrollbar {
  width: 0px;
}

*::-webkit-scrollbar-thumb {
  border-radius: 0px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

.table-head {
  @apply text-left font-semibold dark:text-white label12  text-gray-700 h-11 px-3 border-b dark:border-dark-neutral-700 dark:bg-dark-neutral-800;
}

.table-cell {
  @apply text-gray-800 px-3 py-[6px] overflow-hidden text-left;
}

th {
  @apply table-head;
}

td {
  @apply table-cell;
}

tbody tr {
  @apply transition border-t dark:border-white dark:border-opacity-5;
}

.anchor-link {
  @apply text-blue-500 hover:underline text-ellipsis;
}

.relative-time {
  @apply dark:text-gray-500 label12 font-medium;
}

.transaction-date {
  @apply font-medium dark:text-gray-200 text-gray-500 label12;
}

.h56-responsive {
  letter-spacing: -1.25px;
  @apply text-[56px] leading-[84px] max-sm:text-[36px] max-sm:leading-[45px] max-sm:tracking-[-0.79px];
}

.body20-responsive {
  letter-spacing: -0.33px;
  @apply text-[20px] leading-[30px] max-sm:text-[15.19px] max-sm:leading-[22.79px] max-sm:tracking-[-0.25px];
}

.body18-responsive {
  letter-spacing: -0.26px;
  @apply text-[18px] leading-[27px] max-sm:text-[16px] max-sm:leading-[24px] max-sm:tracking-[-0.18px];
}

.body16-responsive {
  letter-spacing: -0.18px;
  @apply text-[16px] leading-[24px] max-sm:text-[14px] max-sm:leading-[20px] max-sm:tracking-[-0.09px];
}

.body14-responsive {
  letter-spacing: -0.09px;
  @apply text-[14px] leading-[20px] max-sm:text-[12px] max-sm:leading-[18px];
}

.h36-responsive {
  letter-spacing: -0.79px;
  @apply text-[36px] leading-[45px] max-sm:text-[24px] max-sm:leading-[36px] max-sm:tracking-[-0.47px];
}

.h40-responsive {
  letter-spacing: -0.89px;
  @apply text-[40px] leading-[50px] max-sm:text-[24px] max-sm:leading-[36px] max-sm:tracking-[-0.47px];
}
